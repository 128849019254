import React, { Component } from 'react';
import ImageBlock from '../components/imageBlock';
import Title from '../components/title';
import NewLayout from '../layout/newLayout'
import styles from '../styles/scss/pages/howTo.module.scss';
import SEO from "../components/seo"
import PageContents from '../components/pageContents'

import { buildURL } from "react-imgix";

export default class HowToIndex extends Component {
    render() {

        var pg = this.props.pageContext.page

        return (
            <NewLayout>
            {pg.seo.title && <h1 style={{display:'none'}}>{pg.seo.title}</h1>}
            <SEO page={pg} />
            <div className='d-flex flex-column flex-fill'>
                <div className={styles.container}>
                    <div className={styles.infoVideo}>
                        <PageContents content={pg.content} />
                    </div>
                    <div className={styles.resources}>
                        <div className={styles.items}>
                            <Title value='HelpFul Resources' className={styles.title} />
                            <ImageBlock
                                title='All Videos'
                                image='/img/allVideos.png'
                                url='/how-to/videos'
                                containerClass={styles.containerImage}
                                imageClass={styles.bigImage}
                                titleClass={styles.titleBig}
                            />
                            <ImageBlock
                                title='User Stories'
                                image='/img/userStories.png'
                                url='/how-to/user-stories'
                                containerClass={styles.containerImage}
                                imageClass={styles.smallImage}
                                titleClass={styles.titleImage}
                            />
                            <ImageBlock
                                title='Tips'
                                image='/img/tips.png'
                                url='/how-to/tips'
                                containerClass={styles.containerImage}
                                imageClass={styles.smallImage}
                                titleClass={styles.titleImage}
                            />
                            <ImageBlock
                                title='Faqs'
                                image='/img/faqs.png'
                                url='/how-to/faqs'
                                containerClass={styles.containerImage}
                                imageClass={styles.smallImage}
                                titleClass={styles.titleImage}
                            />
                            {pg.relatedPages.map((p)=>{
                                return (
                                    <ImageBlock
                                        key={p.id}
                                        title={p.title}
                                        image={buildURL(p.previewImage.url, {w: 370, fm: 'jpg'})}
                                        url={p.path}
                                        containerClass={styles.containerImage}
                                        imageClass={styles.smallImage}
                                        titleClass={styles.titleImage}
                                    />
                                )
                            })}
                            

                        </div>
                    </div>
                </div>
            </div>
            </NewLayout>
        );
    }
}
